import React from "react";
import "../css/introduction-section.css";
import IntroductionImg from "../assets/introduction-img.webp";

const IntroductionSection = () => {
  return (
    <section className="introduction-section">
      <div className="introduction-section-right swipe">
        <h1 className="after">
          <h1>Introduction of </h1> Hotel Aroma Nainital
        </h1>
        <p>A Home of Serenity in the Tranquil Wood of Ayarpatta Hills</p>
        <p>
          It is surrounded by tall pine & oak trees, the hotel gives you a true
          feel of a vacation in hill station. The hotel caters exclusively to
          the taste of a traveler seeking the serine beauty in the Himalayan
          foothill. It's a preferred accommodation of every traveler visiting
          Nainital for its natural beauty. The ambience and customer service has
          been specially designed to suit the tastes and requirements of budget
          mind leisure travelers with great hospitality.
        </p>
      </div>
      <div className="introduction-section-left swipe-right">
        <img src={IntroductionImg} alt="" />
      </div>
    </section>
  );
};

export default IntroductionSection;
