import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import './App.css';
import HomePage from './pages/homepage';
import ContactUsPage from './pages/contact-us-page';
import AboutUsPage from './pages/about-us-page';
import GalleryPage from './pages/gallery-page';
import RoomsPage from './pages/rooms-page';
import PlaceVisitPage from './pages/place-visit-page';
import SuperDeluxeRoom from './pages/super-deluxe-room';
import FamilyRoom from './pages/family-room';
import Payment from './pages/payment';
import PremiumRoom from './pages/premium-room-page';
import { HelmetProvider } from 'react-helmet-async';


function App() {
  return (
    <>
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/contact" element={<ContactUsPage />} />
            <Route path="/about" element={<AboutUsPage />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="/places-to-visit" element={<PlaceVisitPage />} />
            <Route path="/rooms" element={<RoomsPage />} />
            <Route path="/premium-room" element={<PremiumRoom />} />
            <Route path="/super-deluxe-room" element={<SuperDeluxeRoom />} />
            <Route path="/family-room" element={<FamilyRoom />} />
            <Route path="/payment" element={<Payment />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}

export default App;
