import React, { useState } from "react";
import "../css/virtual-tour-video.css";
import CustomerCountingCard from "./customers-counting-card";

const VirtualTourVideo = () => {
  const [selectedImage, setSelectedImage] = useState(null); // To store the clicked image

  const openImage = (image) => {
    setSelectedImage(image); // Sets the clicked image to display in the popup
  };

  const closeImage = () => {
    setSelectedImage(null); // Closes the popup by resetting the selected image
  };
  return (
    <section className="virtual-tour-video-section">
      <div className="shadow">
        <i class="fa-solid fa-circle-play" onClick={openImage}></i>
        <div>
          <h2>virtual Tour</h2>
          <h2>of Hotel Aroma Nainital</h2>
        </div>
      </div>

      <CustomerCountingCard />

      {/* Popup section */}
      {selectedImage && (
        <div className="popup-image">
          <span className="close-btn" onClick={closeImage}>
            <i className="fa-solid fa-xmark"></i>
          </span>
          <iframe
            width="600"
            height="315"
            src="https://www.youtube.com/embed/NGktwgDeWdM?si=n5p6y3dbWUpl1-jr"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      )}
    </section>
  );
};

export default VirtualTourVideo;
