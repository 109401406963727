import React from 'react'
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/navbar'
import TopBanner from '../components/top-banner'
import HeroSection from '../components/hero-section'
import IntroductionSection from '../components/introduction-section'
import ExploreRoomsSection from '../components/explore-rooms-section'
import Footer from '../components/footer'
import OurServices from '../components/our-services'
import OurGallery from '../components/our-gallery'
import Testimonial from '../components/testimonial'
import VirtualTourVideo from '../components/virtual-tour-video'
import SocialMediaFixed from '../components/social-media-fixed'


const HomePage = () => {


    return (
        <>
            <Helmet>
                <title>Hotel Aroma Nainital - Your Comfort Destination in Nainital</title>
                <meta name="description" content="Welcome to Hotel Aroma Nainital - a serene getaway in the hills of Uttarakhand. Enjoy luxurious accommodations, stunning views of the Himalayas, and exceptional hospitality. Book your stay for a memorable experience in Nainital, close to Naini Lake and popular attractions." />
                <meta name="keywords" content="Hotel Aroma Nainital, Nainital hotel booking, luxury hotel Nainital, hotels near Naini Lake, accommodations Nainital, best hotels in Nainital, hotel with lake view Nainital, hotel Aroma Uttarakhand, Nainital tourism, hotels in Nainital, Nainital stay, Himalayas view hotel" />
                <link rel="canonical" href="https://hotelaromanainital.com/" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Hotel",
                        "name": "Hotel Aroma Nainital - Your Comfort Destination in Nainital",
                        "telephone": "+91 8743000120",
                        "url": "https://hotelaromanainital.com"
                    })}
                </script>
            </Helmet>
            {<TopBanner />}
            {<Navbar />}
            {<HeroSection />}
            {<IntroductionSection />}
            {<ExploreRoomsSection />}
            {<OurServices />}
            {<OurGallery />}
            {<VirtualTourVideo />}
            {<Testimonial />}
            {<Footer />}
            {<SocialMediaFixed />}
        </>
    )
}

export default HomePage
