import React from 'react'
import { Helmet } from 'react-helmet-async'
import Navbar from '../components/navbar'
import TopBanner from '../components/top-banner'
import Footer from '../components/footer'
import OurGallery from '../components/our-gallery'
import SocialMediaFixed from '../components/social-media-fixed'

const GalleryPage = () => {
    return (
        <>
            <Helmet>
                <title>Gallery - Hotel Aroma Nainital</title>
                <meta name="description" content="Explore the stunning visuals of Hotel Aroma Nainital in our gallery. View high-quality images showcasing our rooms, facilities, scenic views, and the natural beauty of Nainital." />
                <meta name="keywords" content="Hotel Aroma Nainital gallery, Nainital hotel images, hotel photo gallery, Nainital hotel views, rooms gallery Hotel Aroma, scenic views Nainital, Nainital hotel pictures, Hotel Aroma accommodations, Nainital tourism photos, hotel facilities Nainital" />
                <link rel="canonical" href="https://hotelaromanainital.com/gallery" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Hotel",
                        "name": "Gallery - Hotel Aroma Nainital",
                        "telephone": "+91 8743000120",
                        "url": "https://hotelaromanainital.com/gallery"
                    })}
                </script>
            </Helmet>

            {<TopBanner />}
            {<Navbar />}
            {<OurGallery />}
            {<Footer />}
            {<SocialMediaFixed />}
        </>
    )
}

export default GalleryPage
