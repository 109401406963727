import React from "react";
import "../css/footer.css";
import { useNavigate } from "react-router-dom";
import logo from "../assets/fav-icon.png";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <>
      <footer>
        <div class="row">
          <div class="footer-col">
            <div className="footer-col-logo">
              <img src={logo} alt="" />
              <h2>Hotel Aroma Nainital</h2>
            </div>
            {/* <p>Your trusted partner in wellness, offering comprehensive medical resources and services.</p> */}
            <div className="contact-col">
              <p>
                <i class="fa-solid fa-location-dot"></i>
                <p>Ayarpatta slopes, Mallital, Nainital, Uttarakhand 263002</p>
              </p>
            </div>
            <div className="contact-col">
              <p>
                <i class="fa-solid fa-phone-volume"></i>
                <a href="tel:+918743000120" target="_blank">
                  +91 8743000120
                </a>
              </p>
              <p>
                <i class="fa-solid fa-phone-volume"></i>
                <a href="tel:+91 9971855186" target="_blank">
                  +91 9971855186
                </a>
              </p>
              <p>
                <i class="fa-solid fa-envelope"></i>
                <a href="mailto:booking@rchronline.com" target="_blank">
                  booking@rchronline.com
                </a>
              </p>
            </div>
            <div className="contact-col">
              <p>
                <i class="fa-regular fa-clock"></i>
                <p>Mon – Sun: 10:30 am – 06.00 pm</p>
              </p>
            </div>
          </div>
          <div class="footer-col">
            <h4>menu</h4>
            <span
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </span>
            <span
              onClick={() => {
                navigate("/about");
              }}
            >
              About us
            </span>
            <span
              onClick={() => {
                navigate("/rooms");
              }}
            >
              Rooms
            </span>
            <span
              onClick={() => {
                navigate("/gallery");
              }}
            >
              Gallery
            </span>
            <span
              onClick={() => {
                navigate("/places-to-visit");
              }}
            >
              Places To Visit
            </span>
            <span
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact Us
            </span>
          </div>
          <div class="footer-col">
            <h4>get help</h4>
            <span
              onClick={() => {
                navigate("");
              }}
            >
              FAQ
            </span>
            <span
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact
            </span>
            <span>Privacy Policy</span>
            <span
              onClick={() => {
                navigate("/payment");
              }}
            >
              Payment Options
            </span>
          </div>
          <div class="footer-col">
            <h4>services</h4>
            <span>Free Wifi</span>
            <span>Restuarant</span>
            <span>Parking</span>
          </div>
          <div class="footer-col">
            <h4>Connect With Us</h4>
            <div class="social-links">
              <a
                href="https://www.instagram.com/hotel_aroma_nainital/"
                target="_blank"
              >
                <i class="fa-brands fa-instagram"></i>
              </a>
              <a href="https://www.facebook.com/aromanainital" target="_blank">
                <i class="fa-brands fa-facebook"></i>
              </a>
              <a
                href="https://www.youtube.com/watch?v=NGktwgDeWdM"
                target="_blank"
              >
                <i class="fa-brands fa-youtube"></i>
              </a>
            </div>
            {/* <div className="news-letter">
              <input type="text" placeholder="Enter your E-Mail" />
              <button>Subscribe</button>
            </div> */}
          </div>
        </div>

        <div className="footer-copyright">
          <div className="footer-copyright-left">
            <p>© 2024</p> <img src="" alt="" />
          </div>

          <div className="footer-copyright-right">All Rights Reserved</div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
