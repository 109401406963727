import React from 'react'
import { Helmet } from 'react-helmet-async';
import TopBanner from '../components/top-banner'
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import PlaceToVisitSection from '../components/place-to-visit-section';
import SocialMediaFixed from '../components/social-media-fixed'


const PlaceVisitPage = () => {

    return (
        <>
            <Helmet>
                <title>Places to Visit in Nainital - Hotel Aroma Nainital</title>
                <meta name="description" content="Discover the best places to visit in Nainital with Hotel Aroma. From Naini Lake to Tiffin Top, explore the most popular attractions and natural beauty that Nainital has to offer." />
                <meta name="keywords" content="places to visit in Nainital, Nainital attractions, sightseeing in Nainital, top places in Nainital, best tourist spots Nainital, Nainital travel guide, Nainital landmarks, Nainital sightseeing spots, must-see places Nainital" />
                <link rel="canonical" href="https://hotelaromanainital.com/places-to-visit" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Hotel",
                        "name": "Places to Visit in Nainital - Hotel Aroma Nainital",
                        "telephone": "+91 8743000120",
                        "url": "https://hotelaromanainital.com/places-to-visit"
                    })}
                </script>
            </Helmet>

            {<TopBanner />}
            {<Navbar />}
            {<PlaceToVisitSection />}
            {<Footer />}
            {<SocialMediaFixed />}
        </>
    )
}

export default PlaceVisitPage;
