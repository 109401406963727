import React from 'react'
import { Helmet } from 'react-helmet-async'
import Navbar from '../components/navbar'
import TopBanner from '../components/top-banner'
import Footer from '../components/footer'
import PaymentComp from '../components/payment-comp'
import SocialMediaFixed from '../components/social-media-fixed'

const Payment = () => {

    return (
        <>
            <Helmet>
                <title>Payment - Hotel Aroma Nainital</title>
                <meta name="description" content="Secure your booking at Hotel Aroma Nainital with our safe and convenient payment options. Complete your reservation process with ease and enjoy a memorable stay in Nainital." />
                <meta name="keywords" content="Hotel Aroma Nainital payment, secure hotel payment, book hotel Nainital, hotel reservation payment, safe payment Hotel Aroma, online hotel booking, Nainital hotel payment options, secure payment gateway hotel, hotel booking confirmation" />
                <link rel="canonical" href="https://hotelaromanainital.com/payment" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Hotel",
                        "name": "Payment - Hotel Aroma Nainital",
                        "telephone": "+91 8743000120",
                        "url": "https://hotelaromanainital.com/payment"
                    })}
                </script>
            </Helmet>

            {<TopBanner />}
            {<Navbar />}
            {<PaymentComp />}
            {<Footer />}
            {<SocialMediaFixed />}
        </>
    )
}

export default Payment
