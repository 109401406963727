import React from "react";
import "../css/our-services.css";
import Wifi from "../assets/icon/wifi-signal.webp";
import Resturant from "../assets/icon/restaurant.webp";
import Parking from "../assets/icon/parking-lot.webp";

const OurServices = () => {
  return (
    <section className="our-services-section">
      <h1>Our Services</h1>
      <div className="services-box">
        <div className="service-box">
          <img src={Wifi} alt="" />
          <h2>Free Wifi</h2>
        </div>
        <div className="service-box">
          <img src={Resturant} alt="" />
          <h2>Restaurant</h2>
        </div>
        <div className="service-box">
          <img src={Parking} alt="" />
          <h2>Parking</h2>
        </div>
      </div>
    </section>
  );
};

export default OurServices;
