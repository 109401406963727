import React, { useState, useEffect } from "react";
import "../css/place-to-visit-section.css";
import PlaceVisitCard from "../components/place-visit-card";
import Loader from "../components/loader";
import Image1 from "../assets/nearby/naini-lake.webp";
import Image2 from "../assets/nearby/nainital-zoo.webp";
import Image3 from "../assets/nearby/ropeway-nainital.webp";
import Image4 from "../assets/nearby/naina-devi-temple.webp";
import Image5 from "../assets/nearby/mall-road.webp";
import Image6 from "../assets/nearby/tiffin-top.webp";
import Image7 from "../assets/nearby/eco-cave.webp";
import Image8 from "../assets/nearby/raj-bhawan.webp";
import Image9 from "../assets/nearby/snow-view-point.webp";
import Image10 from "../assets/nearby/kainchi-dham.webp";
import Image11 from "../assets/nearby/hanuman-garhi.webp";
import Image12 from "../assets/nearby/naina-peak.webp";

// Places data
const places = [
  {
    image: Image1,
    title: "Naini Lake",
    description:
      "Nainital Lake, famously known as Naini Lake is the major attraction of Naintal town as well of Uttarakhand. Surrounded by panoramic seven hills, Nainital lake is a favourite spot among romantic travellers around the world. It is one of the most visited lakes in India.",
  },
  {
    image: Image2,
    title: "Nainital Zoo",
    description:
      "Nainital Zoo is one of the best attraction for wildlife lovers in Nainital. Officially named as Bharat Ratna Pt. Govind Ballabh Pant High Altitude Zoo Nainital, this well maintained zoo is the single zoo in entire Uttarakhand state.",
  },
  {
    image: Image3,
    title: "Aerial Ropeway Nainital",
    description:
      "Cable car or Aerial rope-way is a major attraction in Nainital, which draws hundres of tourists each day who wants to enjoy the breathtaking views of Naini Lake and complete Nainital Hill Station.",
  },
  {
    image: Image4,
    title: "Naina Devi Temple",
    description:
      "A well known Shakti Peeths of Hindu religion, Naini Devi Temple is sacred place of great devotion in Nainital. Goddess Naini Devi is the diety of Naini Devi Temple and located at northern end of famous Nainital Lake.",
  },
  {
    image: Image5,
    title: "Mall Road Nainital",
    description:
      "The Mall is a most popular name in Nainital city. A busiest road in day time, The Mall runs side by side of the Nainital Lake. Britishers constructed The Mall Road which passes through the centre of Nainital.",
  },
  {
    image: Image6,
    title: "Tiffin Top",
    description:
      "A popular picnic spot in Nainital, Tiffin Top (also known as Dorothy's Seat) is located on Ayarpatta hill, offering a magnificent 360 degree view of the Nainital town and surrounding hills.",
  },
  {
    image: Image7,
    title: "Eco Cave Gardens",
    description:
      "Eco Cave Gardens is a newly developed tourist spot in Nainital. It comprises several natural inter-connected caves of different animals. A children educational tour is the best option to explore Eco Cave Gardens.",
  },
  {
    image: Image8,
    title: "Raj Bhawan",
    description:
      "Raj Bhawan, also called Governor House, is a British era building now serving as the residence of the Governor of Uttarakhand. Known for its magnificent looks and old age charming architecture.",
  },
  {
    image: Image9,
    title: "Snow View Point",
    description:
      "Snow View Point is one of the most visited places by tourists for watching majestic Himalayas from Nainital. It offers tourists an excellent view of snow-capped mountains like Nanda Devi, Trishul, and Nanda Kot.",
  },
  {
    image: Image10,
    title: "Kainchi Dham",
    description:
      "Kainchi Dham is a Hanuman temple & Ashram established by a great saint Shri Neem Karoli Baba in the 1960s. It's a holy temple surrounded by hills, trees, and a river flowing beside it.",
  },
  {
    image: Image11,
    title: "Hanuman Garhi",
    description:
      "Hanuman Garhi is located 3.5 kms from the Tallital bus stop, at an altitude of 6,401 ft. Lord Hanuman is the main deity of the temple.",
  },
  {
    image: Image12,
    title: "Naina Peak Trek",
    description:
      "The 6 km trek offers fresh air and beautiful scenery. With Himalayan ranges on one side and Nainital city and lake on the other, it is a highly rewarding trek.",
  },
];

const PlaceToVisitSection = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="place-to-visit-section">
      <h2 className="swipe">Places To Visit</h2>
      <div className="places-to-visit-container ">
        {places.map((place, index) => (
          <PlaceVisitCard
            key={index}
            image={place.image}
            title={place.title}
            description={place.description}
          />
        ))}
      </div>
    </div>
  );
};

export default PlaceToVisitSection;
