import React from "react";
import "../css/why-choose.css";
import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import image1 from "../assets/resot/resot-1.webp";
import image2 from "../assets/resot/resot-2.webp";
import image3 from "../assets/resot/resot-3.webp";
import image4 from "../assets/resot/resot-4.webp";

import icon1 from "../assets/icon/reputation.webp";
import icon2 from "../assets/icon/wi-fi.webp";
import icon3 from "../assets/icon/power.webp";
import icon4 from "../assets/icon/croissant.webp";
import icon5 from "../assets/icon/electric-station.webp";
import icon6 from "../assets/icon/parking.webp";
import icon7 from "../assets/icon/bed.webp";
import icon8 from "../assets/icon/reputation.webp";

const WhyChoose = () => {
  return (
    <section className="why-choose-section">
      <h1>Why Choose Our Resort</h1>
      <p>
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
      </p>
      <div className="why-choose-slider">
        <Swiper
          modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          spaceBetween={30}
          slidesPerView={1}
          style={{ maxWidth: "100%", minWidth: "300px", padding: "0px 0px" }}
          pagination={{ clickable: true }}
          navigation={true}
          breakpoints={{
            320: {
              navigation: false,
            },
            480: {
              navigation: false,
            },
            628: {
              navigation: false,
            },
          }}
        >
          <SwiperSlide>
            <div className="slider-card-testimonial testimonial-content why-choose-slider-image">
              <img src={image1} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider-card-testimonial testimonial-content why-choose-slider-image">
              <img src={image2} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider-card-testimonial testimonial-content why-choose-slider-image">
              <img src={image3} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider-card-testimonial testimonial-content why-choose-slider-image">
              <img src={image4} alt="" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="why-choose-contents">
        <div className="why-choose-contents-box">
          <img src={icon1} alt="" />
          <h2>Customer Satisfication</h2>
        </div>
        <div className="why-choose-contents-box">
          <img src={icon2} alt="" />
          <h2>Free Wifi</h2>
        </div>
        <div className="why-choose-contents-box">
          <img src={icon3} alt="" />
          <h2>Power Backup</h2>
        </div>
        <div className="why-choose-contents-box">
          <img src={icon4} alt="" />
          <h2>Delicious breakfast</h2>
        </div>
        <div className="why-choose-contents-box">
          <img src={icon5} alt="" />
          <h2>EV Charger</h2>
        </div>
        <div className="why-choose-contents-box">
          <img src={icon6} alt="" />
          <h2>Good parking & security</h2>
        </div>
        <div className="why-choose-contents-box">
          <img src={icon7} alt="" />
          <h2>Clean room service</h2>
        </div>
        <div className="why-choose-contents-box">
          <img src={icon8} alt="" />
          <h2>Games Room</h2>
        </div>
      </div>
    </section>
  );
};

export default WhyChoose;
