import React from 'react'
import { Helmet } from 'react-helmet-async'
import Navbar from '../components/navbar'
import TopBanner from '../components/top-banner'
import Footer from '../components/footer'
import ContactUs from '../components/contact-us'
import SocialMediaFixed from '../components/social-media-fixed'

const ContactUsPage = () => {


    return (
        <>
            <Helmet>
                <title>Contact Hotel Aroma Nainital - Get in Touch</title>

                Here’s a set of meta tags for the Contact Page of Hotel Aroma Nainital:

                html
                Copy code
                <meta name="description" content="Get in touch with Hotel Aroma Nainital. Contact us for room bookings, inquiries, or assistance with your stay. We're here to help you plan a memorable experience in Nainital." />
                <meta name="keywords" content="Hotel Aroma Nainital contact, contact hotel in Nainital, hotel booking inquiries, hotel Aroma address, hotel phone number Nainital, Nainital hotel customer support, contact details Hotel Aroma, reach Hotel Aroma Nainital, hotel reservations support, customer service Hotel Aroma" />
                <link rel="canonical" href="https://hotelaromanainital.com/contact" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Hotel",
                        "name": "Contact Hotel Aroma Nainital - +91 8743000120",
                        "telephone": "+91 8743000120",
                        "url": "https://hotelaromanainital.com/contact"
                    })}
                </script>
            </Helmet>
            {<TopBanner />}
            {<Navbar />}
            {<ContactUs />}
            {<Footer />}
            {<SocialMediaFixed />}
        </>
    )
}

export default ContactUsPage
