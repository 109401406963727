import React, { useState } from "react";
import "../css/our-gallery.css";
import GalleryImage1 from "../assets/gallery/g1.webp";
import GalleryImage2 from "../assets/gallery/g2.webp";
import GalleryImage3 from "../assets/gallery/g3.webp";
import GalleryImage4 from "../assets/gallery/g4.webp";
import GalleryImage5 from "../assets/gallery/g5.webp";
import GalleryImage6 from "../assets/gallery/g6.webp";
import GalleryImage7 from "../assets/gallery/g7.webp";
import GalleryImage8 from "../assets/gallery/g8.webp";
import GalleryImage9 from "../assets/gallery/g9.webp";
import GalleryImage10 from "../assets/gallery/g10.webp";
import GalleryImage11 from "../assets/gallery/g11.webp";
import GalleryImage12 from "../assets/gallery/resort-g-1.webp";
import GalleryImage13 from "../assets/gallery/resort-g-2.webp";
import GalleryImage14 from "../assets/gallery/resort-g-3.webp";
import GalleryImage15 from "../assets/gallery/resort-g-4.webp";
import GalleryImage16 from "../assets/gallery/resort-g-5.webp";
import GalleryImage17 from "../assets/gallery/resort-g-6.webp";
import GalleryImage18 from "../assets/gallery/resort-g-7.webp";
import GalleryImage19 from "../assets/gallery/resort-g-8.webp";
import GalleryImage20 from "../assets/gallery/resort-g-9.webp";

const imageList = [
  GalleryImage1,
  GalleryImage2,
  GalleryImage3,
  GalleryImage4,
  GalleryImage5,
  GalleryImage6,
  GalleryImage7,
  GalleryImage8,
  GalleryImage9,
  GalleryImage10,
  GalleryImage11,
  GalleryImage12,
  GalleryImage13,
  GalleryImage14,
  GalleryImage15,
  GalleryImage16,
  GalleryImage17,
  GalleryImage18,
  GalleryImage19,
  GalleryImage20,
];

const OurGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null); // To store the clicked image

  const openImage = (image) => {
    setSelectedImage(image); // Sets the clicked image to display in the popup
  };

  const closeImage = () => {
    setSelectedImage(null); // Closes the popup by resetting the selected image
  };

  return (
    <div className="our-gallery-section">
      <h1 className="swipe">Our Gallery</h1>
      <h4 className="swipe">Explore The Resort</h4>
      <div className="our-gallery-wrapper">
        {imageList.map((image, index) => (
          <div
            className="our-gallery-image swipe"
            key={index}
            onClick={() => openImage(image)}
          >
            <img src={image} alt={`Gallery image ${index + 1}`} />
          </div>
        ))}
      </div>

      {/* Popup section */}
      {selectedImage && (
        <div className="popup-image">
          <span className="close-btn" onClick={closeImage}>
            <i className="fa-solid fa-xmark"></i>
          </span>
          <img src={selectedImage} alt="Popup" />
        </div>
      )}
    </div>
  );
};

export default OurGallery;
