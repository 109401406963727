import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import TopBanner from '../components/top-banner'
import IntroductionSection from '../components/introduction-section'
import Testimonial from '../components/testimonial'
import VirtualTourVideo from '../components/virtual-tour-video'
import WhyChoose from '../components/why-choose'
import SocialMediaFixed from '../components/social-media-fixed'

const AboutUsPage = () => {


    return (
        <>
            <Helmet>
                <title>About Hotel Aroma Nainital - Our Story & Commitment</title>
                <meta name="description" content="Know about Hotel Aroma Nainital - a luxury hotel offering serene accommodations, exceptional service, and breathtaking views of Nainital's natural beauty. Discover our mission, values, and commitment to making your stay unforgettable." />
                <meta name="keywords" content="About Hotel Aroma Nainital, luxury hotel Nainital, Hotel Aroma mission, hotel story Nainital, accommodations Nainital, hospitality Nainital, luxury stay in Nainital, hotel history Nainital, best hotels Nainital, about us Hotel Aroma" />

                <link rel="canonical" href="https://hotelaromanainital.com/about" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Hotel",
                        "name": "About Hotel Aroma Nainital - Our Story & Commitment",
                        "telephone": "+91 8743000120",
                        "url": "https://hotelaromanainital.com/about"
                    })}
                </script>
            </Helmet>
            {<TopBanner />}
            {<Navbar />}
            {<IntroductionSection />}
            {<WhyChoose />}
            {<VirtualTourVideo />}
            {<Testimonial />}
            {<Footer />}

            {<SocialMediaFixed />}
        </>
    )
}

export default AboutUsPage
