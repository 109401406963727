import React from "react";
import CountUp from "react-countup";
import "../css/customers-counting-card.css";

const CustomerCountingCard = () => {
  return (
    <section className="customers-counting-card-section">
      <div className="customers-counting-card-box">
        <h2>
          <CountUp start={0} end={712} duration={5} />
        </h2>

        <h4>
          Happy <br /> Guests
        </h4>
      </div>
      <div className="customers-counting-card-box">
        <h2>
          <CountUp start={0} end={254} duration={5} />
        </h2>
        <h4>
          Return <br /> Guests
        </h4>
      </div>
      <div className="customers-counting-card-box">
        <h2>
          <CountUp start={0} end={689} duration={5} />
        </h2>
        <h4>
          five stars <br /> rating
        </h4>
      </div>
      <div className="customers-counting-card-box">
        <h2>
          <CountUp start={0} end={782} duration={5} />
        </h2>
        <h4>
          Served <br /> Breakfast
        </h4>
      </div>
    </section>
  );
};

export default CustomerCountingCard;
