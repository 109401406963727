import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../css/navbar.css";
import logo from "../assets/fav-icon.png";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [navbarsection, setNavbarSection] = useState(false);

  const navbarFixed = () => {
    if (window.scrollY >= 100) {
      setNavbarSection(true);
    } else {
      setNavbarSection(false);
    }
  };

  window.addEventListener("scroll", navbarFixed);

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <nav className={navbarsection ? "navbar activated" : "navbar"}>
      <input
        type="checkbox"
        id="check"
        checked={isOpen}
        onChange={toggleHandler}
        style={{ display: "none" }}
      />
      <div
        className="logo"
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={logo} alt="Hotel Aroma Nainital Logo" />
        <h2>Hotel Aroma Nainital</h2>
      </div>
      <div className={`menu ${isOpen ? "open" : ""}`}>
        <li
          className={isActive("/")}
          onClick={() => {
            navigate("/");
          }}
        >
          Home
        </li>
        <li
          className={isActive("/about")}
          onClick={() => {
            navigate("/about");
          }}
        >
          About Us
        </li>
        <li
          className={isActive("/rooms")}
          onClick={() => {
            navigate("/rooms");
          }}
        >
          Rooms
        </li>
        <li
          className={isActive("/gallery")}
          onClick={() => {
            navigate("/gallery");
          }}
        >
          Gallery
        </li>
        <li
          className={isActive("/places-to-visit")}
          onClick={() => {
            navigate("/places-to-visit");
          }}
        >
          Places To Visit
        </li>
        <li
          className={isActive("/payment")}
          onClick={() => {
            navigate("/payment");
          }}
        >
          Payment
        </li>
        <li
          className={isActive("/contact")}
          onClick={() => {
            navigate("/contact");
          }}
        >
          Contact Us
        </li>
      </div>
      <div className="book">
        <button
          onClick={() => {
            navigate("/contact");
          }}
        >
          Book Now
        </button>
        <div className="menu-icon" onClick={toggleHandler}>
          {isOpen ? (
            <i className="fa-solid fa-xmark"></i> // Close icon
          ) : (
            <i className="fa-solid fa-bars"></i> // Open icon
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
