import React from "react";
import "../css/place-visit-card.css";

const PlaceVisitCard = ({ image, title, description }) => {
  return (
    <div className="place-visit-card-section swipe">
      <div className="place-visit-card-image">
        <img src={image} alt={title} />
      </div>
      <div className="place-visit-card-content">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default PlaceVisitCard;
